import {Model} from '../../abstract/model';

export class Tag extends Model {
  id: number;
  name: string;
  status: string;

  toString() {
    return `${this.name}`;
  }
}
