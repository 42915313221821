import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StorageService} from './storage/storage.service';
import {AuthService} from './auth/auth.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserProvider} from './user/user.provider';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthTokenProvider} from './auth/auth-token-provider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RoutingModule} from '../routing/routing.module';
import {TravelResourceService} from './api/travel-resource/travel-resource.service';
import {ClientRequestService} from './api/client-request/client-request.service';
import {LocationCityService} from './api/location/location-city.service';
import {ClientService} from './api/client/client.service';
import {OriginService} from './api/origin/origin.service';
import {TravelConsultantService} from './api/travel-consultant/travel-consultant.service';
import {FileService} from './api/file/file.service';
import {EmailMessageService} from './api/email-message/email-message.service';
import {BroadcastService} from './broadcast/broadcast.service';
import {ClientRequestTimelineItemService} from './api/client-request/client-request-timeline-item.service';
import {ErrorInterceptor} from './api/error.interceptor';
import {SettingService} from './api/setting/setting.service';
import {LocationCountryService} from './api/location/location-country.service';
import {SharedPaymentService} from 'app/core/api/shared-payment/shared-payment.service';
import {SharedPaymentShareService} from './api/shared-payment-share/shared-payment-share.service';
import {KibanaReportService} from './api/kibana-report/kibana-report.service';
import {EntityService} from './api/entity/entity.service';
import {GenericCampaignService} from './api/generic-campaign/generic-campaign.service';
import {AclRoleGroupService} from './api/acl/acl-role-group.service';
import {AclTemplateService} from './api/acl/acl-template.service';
import {PermissionGroupService} from './api/permission-group/permission-group.service';
import {CityDetailProvider} from './CityDetail/city-detail-provider';
import {TravelResourceGroupService} from './api/travel-resource-group/travel-resource-group.service';
import {TravelResourceTypeService} from './api/travel-resource-type/travel-resource-type-service';
import {TagService} from './api/tag/tag.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RoutingModule,

    /**
     * Angular Material modules (see https://material.angular.io)
     */
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  declarations: [
  ],
  providers: [
    AuthTokenProvider,
    StorageService,

    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},

    /**
     * CDTSYS API Services
     */

    AuthService,
    AclRoleGroupService,
    AclTemplateService,
    BroadcastService,
    ClientRequestService,
    ClientRequestTimelineItemService,
    ClientService,
    EmailMessageService,
    FileService,
    GenericCampaignService,
    LocationCityService,
    LocationCountryService,
    OriginService,
    EntityService,
    SettingService,
    SharedPaymentService,
    SharedPaymentShareService,
    TravelConsultantService,
    TravelResourceService,
    KibanaReportService,
    UserProvider,
    CityDetailProvider,
    PermissionGroupService,
    TravelResourceGroupService,
    TravelResourceTypeService,
    TagService
  ],
  exports: [
  ]
})
export class CoreModule { }
