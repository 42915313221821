import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {DebugService} from './debug.service';

@Directive({
  selector: '[appDebug]'
})
export class DebugDirective implements OnInit {
  @Input() appDebug;
  private enabled = false;

  constructor(
    private debugService: DebugService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit() {
    this.debugService.observeEnable().subscribe(enabled => {
      this.enabled = enabled;
      this.updateView();
    });
  }

  private updateView() {
    if (this.enabled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
