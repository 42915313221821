
import {map} from 'rxjs/operators';
import {StorageService} from '../storage/storage.service';
import {Injectable} from '@angular/core';
import {ReplaySubject, Observable} from 'rxjs';


@Injectable()
export class AuthTokenProvider {
  readonly TOKEN_STORAGE_KEY = 'auth.token';
  private tokenSubject: ReplaySubject<AuthToken> = new ReplaySubject(1);
  private fetchTokenObservable: Observable<AuthToken>;

  constructor(
    public storage: StorageService,
  ) {
    this.fetchToken().subscribe(() => {});
  }

  /**
   * @returns
   */
  observeToken(): Observable<AuthToken> {
    return this.tokenSubject.asObservable();
  }

  /**
   * Return an observable that resolves when AuthService
   * is ready (token loaded from storage).
   *
   * @returns
   */
  observeReady(): Observable<AuthToken> {
    return this.fetchTokenObservable;
  }

  /**
   * @param token
   */
  public setToken(token: AuthToken): Observable<any> {
    return this.storage.set(this.TOKEN_STORAGE_KEY, token).pipe(map(() => {
      this.tokenSubject.next(token);

      return token;
    }));
  }

  /**
   * Logout
   *
   * @returns
   */
  public removeToken(): Observable<any> {
    return this.storage.remove(this.TOKEN_STORAGE_KEY).pipe(map(() => {
      this.tokenSubject.next(null);
    }));
  }

  /**
   * Fetch token from storage if available
   */
  private fetchToken(): Observable<AuthToken> {
    // This avoids multiple unnecessary storage calls
    if (!this.fetchTokenObservable) {

      this.fetchTokenObservable = this.storage.get(this.TOKEN_STORAGE_KEY).pipe(map((token) => {
        this.tokenSubject.next(token);
        return token;
      }));

      return this.fetchTokenObservable;
    }
  }
}
