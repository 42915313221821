import {Model} from '../abstract/model';
import {LocationCity} from '../api/location/location-city';
import {File} from '../api/file/file';
import {TravelResourceGroup} from '../api/travel-resource-group/travel-resource-group';
import {CityDetailResponse} from './city-detail-response';

export class CityDetail extends Model {
  public id: number;
  public city: LocationCity;
  files: Array<File> = [];
  public created_at?: Date;
  public updated_at?: Date;
  public travel_resource_groups?: Array<TravelResourceGroup> = [];

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: CityDetailResponse): CityDetail {
    const cityDetail = new this(response);

    cityDetail.created_at = response.created_at ? new Date(response.created_at) : null;
    cityDetail.updated_at = response.updated_at ? new Date(response.updated_at) : null;
    cityDetail.city = response.city ? LocationCity.createFromResponse(response.city) : null;
    cityDetail.files = response.files ? File.collectionFromResponse(response.files) : null;

    if (response.travel_resource_groups) {
      cityDetail.travel_resource_groups = TravelResourceGroup.collectionFromResponse(response.travel_resource_groups);
    } else {
      cityDetail.travel_resource_groups = null;
    }
    return cityDetail;
  }
}
