import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { RoutingModule } from './routing/routing.module';
import {CoreModule} from './core/core.module';
import {LoginComponent} from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {UiModule} from './ui/ui.module';
import {environment} from '../environments/environment';
import {A11yModule} from '@angular/cdk/a11y';
import {FlexLayoutModule} from '@angular/flex-layout';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LogoutComponent } from './logout/logout.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {DebugDirective} from './debug/debug.directive';
import {DebugModule} from './debug/debug.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RoutingModule,
    FlexLayoutModule,

    UiModule,
    DebugModule,

    /**
     * Angular Material modules (see https://material.angular.io)
     */
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    A11yModule,
    MatSliderModule,

    /**
     * Google Maps
     */

    // AgmCoreModule.forRoot({
    //   apiKey: environment.GOOGLE_MAPS_API_KEY
    // }),

    /**
     * Application Modules
     */

    CoreModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
