import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {File as FileModel} from './file';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {UploadFormResponse} from './upload-form.response';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {FileResponse} from './file.response';

@Injectable()
export class FileService extends ApiProvider<FileModel, FileResponse> {
  protected resourceName = 'file';

  collectionFromResponse(response: CollectionResponse<FileResponse>): Array<FileModel> {
    return FileModel.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<FileResponse>): FileModel {
    return FileModel.createFromResponse(response.data);
  }

  /**
   * @param file
   *
   * @returns
   */
  public upload(file: File): Observable<HttpEvent<SingleResponse<FileResponse>>> {
    let path;
    let filename;
    return this.getUploadForm().pipe(
      switchMap((uploadForm) => {
        path = uploadForm.formInputs.key;
        const formData: FormData = new FormData();

        filename = file.name;

        formData.append('X-Amz-Meta-filename', filename);
        formData.append('Content-Type', file.type);

        for (const key in uploadForm.formInputs) {
          if (uploadForm.formInputs.hasOwnProperty(key)) {
            formData.append(key, uploadForm.formInputs[key]);
          }
        }

        formData.append('file', file);

        const headers = new HttpHeaders({
          DisableAuthInterceptor: 'true'
        });

        const request = new HttpRequest<FormData>('POST', uploadForm.formAttributes.action, formData, {
          headers,
          reportProgress: true
        });

        return this.http.request<void>(request);
      }),
      switchMap((event) => {
        if (event.type === HttpEventType.Response) {
          const url = new URL(`${environment.api.endpoint}/${this.resourceName}/upload`).toString();

          const request = new HttpRequest<any>('POST', url, {
            path,
            filename
          });

          return this.http.request<SingleResponse<FileResponse>>(request);
        } else {
          return of(event);
        }
      })
    );
  }

  public getUploadForm(): Observable<UploadFormResponse> {
    const url = new URL(`${environment.api.endpoint}/${this.resourceName}/upload`).toString();

    return this.http.get<UploadFormResponse>(url);
  }
}
