import {Model} from '../../abstract/model';

export class GoogleClick extends Model {
  /**
   * The model type on API
   *
   * @type {string}
   */
  static model_type = 'App\\Models\\GoogleClick';

  id: number;
  gclid: string;
  click_performance_report: any;
  ad_performance_report: any;
  created_at: Date;
  updated_at: Date;
  date: Date;

  /**
   * @returns
   * @param response
   */
  public static createFromResponse(response: GoogleClickResponse): GoogleClick {
    const googleClick = new this(response);

    googleClick.created_at = new Date(response.created_at);
    googleClick.updated_at = new Date(response.updated_at);
    googleClick.date = new Date(response.date);

    if (googleClick.ad_performance_report) {
      googleClick.ad_performance_report.finalURL = JSON.parse(response.ad_performance_report.finalURL);
    }

    return googleClick;
  }
}
