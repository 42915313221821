import {Directive} from '@angular/core';
import {HasRoleDirective} from './has-role.directive';

@Directive({
  selector: '[appHasNotRole]'
})
export class HasNotRoleDirective extends HasRoleDirective {
  protected checkRoles() {
    return !super.checkRoles();
  }
}
