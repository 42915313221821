import {Model} from '../../abstract/model';

export class File extends Model {
  id: number;
  label: string;
  filename: string;
  extension: string;
  view_link: string;
  download_link: string;
  thumbnail_link: string;
  size: number;
  default: boolean;

  /**
   * Indicates either the file is broken or not.
   * This can be used for images, allowing to flag
   * this image as broken and replacing it by a placeholder
   */
  broken = false;

  /**
   * Indicates either the file was loaded or not.
   * This can be used for images, allowing to flag
   * image as pre-loaded, and show it only when pre-loaded.
   */
  load = false;

  /**
   * @deprecated use filename property instead
   */
  get filenameWithExtension() {
    return this.filename;
  }

  get isImage() {
    return ['png', 'jpg', 'gif', 'jpeg', 'svg'].indexOf(this.extension.toLowerCase()) !== -1;
  }
}
