import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Origin} from './origin';

@Injectable()
export class OriginService extends ApiProvider<Origin, OriginResponse> {
  protected resourceName = 'origin';

  collectionFromResponse(response: CollectionResponse<OriginResponse>): Array<Origin> {
    return Origin.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<OriginResponse>): Origin {
    return Origin.createFromResponse(response.data);
  }
}
