import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {ChangePasswordDialogComponent} from './change-password-dialog.component';
import {User} from '../../core/user/user';
import {AuthService} from '../../core/auth/auth.service';
import {environment} from '../../../environments/environment';
import {Breadcrumb} from './breadcrumb';
import {MENU_ITEMS} from './menu-items';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit, OnChanges {
  static allMenuSections = MENU_ITEMS;

  @Input() public breadcrumbs: Array<Breadcrumb>;
  @Input() public toolbar = false;
  @Input() public panelTitle?: string;
  @Input() showToolbar = true;

  public menuSections: any = [];
  public user: User;
  public version = environment.version;

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.observeUser().subscribe((user) => {
      this.user = user;

      this.parseMenuItems();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const pieces = [...(this.breadcrumbs || []).map((breadcrumb) => breadcrumb.title)];
    if (this.panelTitle) {
      pieces.push(this.panelTitle);
    }

    this.titleService.setTitle(
      [
        pieces.filter((piece) => !!piece).join(' › '),
        'CDTSYS'
      ].filter((piece) => !!piece).join(' | ')
    );
  }

  /**
   * Logout and redirect to app-login.
   */
  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  openChangePasswordModal() {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '350px',
      data: this.user,
    });
  }

  parseMenuItems() {
    this.menuSections = PanelComponent.allMenuSections.map((section) => {
      section = Object.assign({}, section);
      section.items = section.items.slice().filter((item) => {
        let hasAllRoles = true;

        if (item.roles) {
          item.roles.forEach((role) => {
            if (!this.user || !this.user.hasRole(role)) {
              hasAllRoles = false;
            }
          });
        }

        return hasAllRoles;
      });

      return section;
    }).filter((section) => !!section.items.length);
  }
}
