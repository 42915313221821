import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef, EmbeddedViewRef,
  Injector,
  Input,
  OnInit, Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {LoadingService} from './loading.service';
import {Subscription} from 'rxjs';
import {LoadingComponent} from './loading.component';

@Directive({
  selector: '[appLoading]'
})
export class AppLoadingDirective implements OnInit {
  private tag: string;
  private active = false;
  private tagSubscription: Subscription;
  private componentRef: ComponentRef<LoadingComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
    private loadingService: LoadingService,
    private injector: Injector,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
  }

  @Input()
  set appLoading(tag: string) {
    this.tag = tag;

    this.observeTag();
    this.updateView();
  }

  private observeTag() {
    if (this.tagSubscription) {
      this.tagSubscription.unsubscribe();
    }

    this.tagSubscription = this.loadingService.observe(this.tag).subscribe((active) => {
      this.active = active;
      this.updateView();
    });
  }

  private buildComponent() {
    if (!this.componentRef) {
      this.componentRef = this.viewContainer.createComponent(this.componentFactoryResolver.resolveComponentFactory(LoadingComponent));
    }
  }

  private destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }

  private updateView() {
    if (this.active) {
      this.buildComponent();

      this.renderer.appendChild(this.element.nativeElement, this.componentRef.location.nativeElement);
      // this.element.nativeElement.append(this.componentRef.hostView);
      // this.viewContainer.insert(this.componentRef.hostView, 0);
      // this.element.nativeElement.insert(this.componentRef.hostView, 0);
      // this.viewRef.context.elementRef.nativeElement.insert(this.componentRef.hostView, 0);
    } else {
      this.destroyComponent();
    }
  }
}
