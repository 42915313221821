import {Model} from '../../abstract/model';
import {Bank} from '../bank/bank';

export class Entity extends Model {
  id: number;
  default_bank_id: number;
  country_id: number;
  company_name: string;
  country_fiscal_code: string;
  state_fiscal_code: string;
  city_fiscal_code: string;
  default: boolean;
  invoice_address: string;
  default_bank: Bank;
}
