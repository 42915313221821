import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private enabled: boolean;
  private enabledSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor() {
    this.disable();
  }

  enable() {
    this.enabledSubject.next(true);
    this.enabled = true;
  }

  disable() {
    this.enabledSubject.next(false);
    this.enabled = false;
  }

  observeEnable(): Observable<boolean> {
    return this.enabledSubject.asObservable();
  }

  toggle() {
    if (this.enabled) {
      this.disable();
    } else {
      this.enable();
    }
  }
}
