import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {PermissionGroup} from './permission-group';
import {PermissionGroupResponse} from './permission-group.response';

@Injectable()
export class PermissionGroupService extends ApiProvider<PermissionGroup, PermissionGroupResponse> {
  protected resourceName = 'permission_group';

  collectionFromResponse(response: CollectionResponse<PermissionGroupResponse>): Array<PermissionGroup> {
    return PermissionGroup.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<PermissionGroupResponse>): PermissionGroup {
    return PermissionGroup.createFromResponse(response.data);
  }
}
