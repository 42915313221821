import {Model} from '../../abstract/model';
import {User} from '../../user/user';
import {TravelConsultantResponse} from './travel-consultant.response';

export class TravelConsultant extends Model {
  id: number;
  user: User;
  unbalancing_offset: number;
  weight: number;

  /**
   * @returns
   * @param response
   */
  public static createFromResponse(response: TravelConsultantResponse): TravelConsultant {
    const travelConsultant = new this(response);

    travelConsultant.user = travelConsultant.user ? User.createFromResponse(response.user) : null;

    return travelConsultant;
  }

  /**
   * @returns
   */
  public toString(): string {
    return `${this.user.name} ${this.user.surname}`;
  }
}
