import { Injectable } from '@angular/core';

import {ApiProvider} from '../api';

import {TravelResourceGroup} from './travel-resource-group';
import {TravelResourceGroupResponse} from './travel-resource-group-response';

@Injectable()
export class TravelResourceGroupService extends ApiProvider<TravelResourceGroup, TravelResourceGroupResponse> {
  protected resourceName = 'travel_resource_group';

  createFromResponse(response: SingleResponse<TravelResourceGroupResponse>): TravelResourceGroup {
    return TravelResourceGroup.createFromResponse(response.data);
  }

  collectionFromResponse(response: CollectionResponse<TravelResourceGroupResponse>): Array<TravelResourceGroup> {
    return TravelResourceGroup.collectionFromResponse(response.data);
  }
}
