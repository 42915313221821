import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {LocationCity} from './location-city';

@Injectable()
export class LocationCityService extends ApiProvider<LocationCity, LocationCityResponse> {
  protected resourceName = 'location_city';

  collectionFromResponse(response: CollectionResponse<LocationCityResponse>): Array<LocationCity> {
    return LocationCity.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<LocationCityResponse>): LocationCity {
    return LocationCity.createFromResponse(response.data);
  }
}
