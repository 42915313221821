import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {SharedPayment} from './shared-payment';
import {SharedPaymentResponse} from './shared-payment.response';

@Injectable()
export class SharedPaymentService extends ApiProvider<SharedPayment, SharedPaymentResponse> {
  protected resourceName = 'shared_payment';

  collectionFromResponse(response: CollectionResponse<SharedPaymentResponse>): Array<SharedPayment> {
    return SharedPayment.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<SharedPaymentResponse>): SharedPayment {
    return SharedPayment.createFromResponse(response.data);
  }
}
