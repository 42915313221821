import {Model} from '../../abstract/model';

export class TravelResourceType extends Model {
  id: number;
  label: string;
  localized: any; // @todo: To be defined;

  /**
   * @param items
   * @returns
   */
  public static createFromResponse(items: any): TravelResourceType {
    return new this(items);
  }

  public toString() {
    return `${this.label}`;
  }
}
