<ng-container>
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="sidemenu.toggle()">
      <mat-icon aria-label="Toggle Menu">menu</mat-icon>
    </button>
    <div class="logo-container">
      <img class="logo" src="../assets/img/logo-white.svg" alt="CDTSYS" title="CDTSYS v{{ version }}">
      <span class="version">v{{ version }} <span *appDebug>[Debug]</span></span>
    </div>
    <app-fill class="breadcrumbs">
      <span class="breadcrumb"
         *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <a *ngIf="breadcrumb.url" [href]="breadcrumb.url">{{ breadcrumb.title }}</a>
        <span *ngIf="!breadcrumb.url">{{ breadcrumb.title }}</span>
        <mat-icon *ngIf="!(last && !panelTitle)">arrow_right</mat-icon>
      </span>
      <span *ngIf="panelTitle">{{ panelTitle }}</span>
    </app-fill>
    <button mat-icon-button label="See notifications">
      <mat-icon>notifications_none</mat-icon>
    </button>
    <button mat-icon-button label="Your account" [matMenuTriggerFor]="accountMenu">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false">
      <span mat-menu-item disabled>{{ user?.name }} {{ user?.surname }}</span>
<!-- Removed after password field removal (only Google login enabled) -->
<!--      <button mat-menu-item (click)="openChangePasswordModal()">-->
<!--        <mat-icon>security</mat-icon>-->
<!--        Change Password-->
<!--      </button>-->
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Logout
      </button>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidemenu mode="over">
      <mat-nav-list *ngFor="let section of menuSections; let first = first">
        <mat-divider *ngIf="!first"></mat-divider>
        <h3 mat-subheader class="tc-white">{{section.title}}</h3>
        <a mat-list-item class="tc-white" *ngFor="let item of section.items" [routerLink]="item.link">
          <mat-icon class="tc-white" matListIcon>{{ item.icon}}</mat-icon> {{ item.title }}</a>
      </mat-nav-list>
      <span class="version">v{{ version }}</span>
    </mat-sidenav>
    <mat-sidenav-content [appLoading]="'panel-content'">
      <div class="toolbar" *ngIf="showToolbar">
        <ng-content select="mat-toolbar"></ng-content>
      </div>
      <div class="content">
        <ng-content select=".panel-content"></ng-content>
        <div class="fab-buttons">
          <ng-content select=".fab-buttons > *"></ng-content>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
