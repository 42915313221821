import {map, switchMap, tap} from 'rxjs/operators';
import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiProvider} from '../api/api';
import {from, Observable, ReplaySubject} from 'rxjs';
import {User} from '../user/user';
import {UserProvider} from '../user/user.provider';
import {environment} from '../../../environments/environment';
import {StorageService} from '../storage/storage.service';
import {AuthTokenProvider} from './auth-token-provider';
// @ts-ignore
import LogRocket from 'logrocket';

declare let gapi: any;

@Injectable()
export class AuthService extends ApiProvider<any, any> {
  private userSubject: ReplaySubject<User> = new ReplaySubject(1);
  private fetchingUser = false;
  private gapi: any;

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public userProvider: UserProvider,
    public tokenProvider: AuthTokenProvider,
    private zone: NgZone
  ) {
    super(http);

    this.tokenProvider.observeToken().subscribe((token) => {
      if (token) {
        this.fetchUser();
      } else {
        this.userSubject.next(null);
      }
    });

    this.observeUser().subscribe((user) => {
      if (user) {
        LogRocket.identify(user.id.toString(), {
          name: user.name + ' ' + user.surname,
          email: user.email,
        });
      } else {
        LogRocket.identify('guest');
      }
    });
  }

  /**
   * @returns
   */
  public observeUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  /**
   * Get OAuth2 token using google id_token as credential
   *
   * @param id_token
   */
  public loginWithGoogleUserIdToken(id_token): Observable<boolean> {
    return this.http.post<AuthToken>(`${environment.api.endpoint}/oauth/token`, {
      grant_type: 'google',
      client_id: environment.api.client_id,
      client_secret: environment.api.client_secret,
      id_token,
      scope: '',
    }).pipe(
      switchMap((token: AuthToken) => {
        this.userSubject = new ReplaySubject(1);

        return this.tokenProvider.setToken(token);
      }),
      switchMap(() => {
        this.fetchUser();

        return this.userSubject.asObservable();
      }),
      map(() => true)
    );
  }

  /**
   * Redirect to legacy auth url
   *
   * @param id_token
   * @param intendedUrl
   */
  public legacyLoginWithGoogleUserIdToken(id_token, intendedUrl?: string) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${environment.api.endpoint}/auth/legacy/google`;
    form.style['display'] = 'none';
    const idTokenInput = document.createElement('input');
    idTokenInput.type = 'hidden';
    idTokenInput.name = 'id_token';
    idTokenInput.value = id_token;
    form.appendChild(idTokenInput);
    const intendedUrlInput = document.createElement('input');
    intendedUrlInput.type = 'hidden';
    intendedUrlInput.name = 'intended_url';
    intendedUrlInput.value = intendedUrl;
    form.appendChild(intendedUrlInput);
    document.body.appendChild(form);
    form.submit();
  }

  /**
   * Logout
   *
   * @returns
   */
  logout(): Observable<void> {
    return this.googleLogout().pipe(
      switchMap(() => this.apiLogout()),
      switchMap(() => this.tokenProvider.removeToken().pipe(map(() => {
          this.userSubject.next(null);
        })))
    );
  }

  apiLogout(): Observable<void> {
    return this.http.get<void>(`${environment.api.endpoint}/auth/logout`);
  }

  googleLogout(): Observable<any> {
    return from(this.getGapi())
      .pipe(
        switchMap((gapiInstance) => new Observable((subscriber) => {
            gapiInstance.auth2.getAuthInstance().signOut().then(() => {
              this.zone.run(() => {
                subscriber.next();
                subscriber.complete();
              });
            });
          })),
      );
  }

  getModel(): User {
    return new User();
  }

  async renderSignIn2(id: string, successHandler?: (response: any) => void) {
    const gapiInstance = await this.getGapi();

    return this.zone.run(() => gapiInstance.signin2.render(id, {
        onsuccess: (r) => this.zone.run(() => {
          successHandler(r);
        })
      })
    );
  }

  private async getGapi() {
    if (this.gapi) {
      return this.gapi;
    }

    return await (new Promise((resolve) => {
      if (typeof document !== 'undefined' && typeof gapi === 'undefined') {
        const meta = document.createElement('meta');
        meta.name = 'google-signin-client_id';
        meta.content = environment.google_oauth2_client_id;
        document.head.append(meta);

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://apis.google.com/js/platform.js';
        script.onload = () => this.zone.run(() => {
          this.gapi = gapi;
          gapi.load('auth2', () => {
            this.zone.run(() => {
              gapi.auth2.init().then(() => {
                resolve(gapi);
              });
            });
          });
        });
        document.head.append(script);
      }
    }));
  }

  /**
   * Fetch user from API if token is available
   */
  private fetchUser() {
    if (!this.fetchingUser) {
      return this.userProvider.getCurrentUser().subscribe((user: User) => {
        this.userSubject.next(user);
        this.fetchingUser = false;
      }, (error) => {
        if (error.status === 401) {
          this.tokenProvider.removeToken();
          this.userSubject.next(null);
        } else {
          throw error;
        }
      });
    }
  }
}
