import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Setting} from './setting';
import {SettingResponse} from './setting.response';
import {Observable} from 'rxjs';
import {SettingValue} from './setting-value';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {SettingValueResponse} from './setting-value.response';

@Injectable()
export class SettingService extends ApiProvider<Setting, SettingResponse> {
  protected resourceName = 'setting';

  collectionFromResponse(response: CollectionResponse<SettingResponse>): Array<Setting> {
    return Setting.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<SettingResponse>): Setting {
    return Setting.createFromResponse(response.data);
  }

  loadValue(settingCode: string, userId?: string|number): Observable<SettingValue> {
    let url: string|URL = `${environment.api.endpoint}/${this.resourceName}/${settingCode}/value`;

    if (userId) {
      url += `/${userId}`;
    }

    url = new URL(url);

    return this.http.get<SingleResponse<SettingValueResponse>>(url.toString()).pipe(
      map((response) => SettingValue.createFromResponse(response.data)));
  }

  setValue(settingCode: string, value: any, userId?: string|number): Observable<SettingValue> {
    let url: string|URL = `${environment.api.endpoint}/${this.resourceName}/${settingCode}/value`;

    if (userId) {
      url += `/${userId}`;
    }

    url = new URL(url);

    return this.http.patch<SingleResponse<SettingValueResponse>>(url.toString(), { value }).pipe(
      map((response) => SettingValue.createFromResponse(response.data)));
  }
}
