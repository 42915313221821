import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {KibanaReport} from './kibana-report';
import {KibanaReportResponse} from './kibana-report.response';

@Injectable()
export class KibanaReportService extends ApiProvider<KibanaReport, KibanaReportResponse> {
  protected resourceName = 'kibana/report';

  collectionFromResponse(response: CollectionResponse<KibanaReportResponse>): Array<KibanaReport> {
    return KibanaReport.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<KibanaReportResponse>): KibanaReport {
    return KibanaReport.createFromResponse(response.data);
  }
}
