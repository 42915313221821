import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeUrlPipe} from './safe-url.pipe';
import {SafeStyleUrlPipe} from './safe-style-url.pipe';
import {TimeAgoPipe} from './time-ago.pipe';

@NgModule({
  declarations: [
    SafeUrlPipe,
    SafeStyleUrlPipe,
    TimeAgoPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeUrlPipe,
    SafeStyleUrlPipe,
    TimeAgoPipe,
  ],
})
export class PipesModule { }
