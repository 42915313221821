import {
  AfterViewInit, Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild,
  SimpleChanges
} from '@angular/core';
import {ApiProvider} from '../../core/api/api';
import {ModelListColumn} from './model-list-column';
import {SelectionModel} from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Model} from '../../core/abstract/model';

import {of as observableOf, Subscription} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingService} from '../loading/loading.service';
import {DialogService} from '../dialog/dialog.service';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss']
})
export class ModelListComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() columns: Array<ModelListColumn>;
  @Input() apiSettings: {columns?: Array<string>; include: Array<string> };
  @Input() paginate = true;
  @Input() selectable = true;
  @Input() enableFilters = true;
  @Input() filterOpened = false;
  @Input() filterQuery?: string;
  @Input() filterJson?: string;
  @Input() filterCount?: number;
  @Input() panelTitle?: string;
  @Input() highlight?: (any) => boolean;
  @Input() modelProvider: ApiProvider<any, any>;
  @Input() pageIndex = 0;
  @Input() pageSize = 25;
  @Input() deleteRole: string;
  @Input() sort: Sort = {
    active: 'id',
    direction: 'desc'
  };

  @Output() rowClick = new EventEmitter<any|Model>();
  @Output() selectionChange = new EventEmitter<Array<Model>>();
  @Output() searchFailed = new EventEmitter<HttpErrorResponse>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  selection = new SelectionModel<Model>(true, []);
  dataSource: MatTableDataSource<Model> = new MatTableDataSource<Model>();

  isLoadingResults = true;

  fullQuery: string;
  resultsLength = 0;
  searchQuery?: string;

  get visibleColumns(): Array<string> {
    const columns = this.columns.map(column => column.name);
    if (this.selectable) {
      columns.unshift('select');
    }
    return columns;
  }

  private fetchDataSubscription: Subscription;

  constructor(
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.selection.changed.subscribe((value) => {
      this.selectionChange.emit(this.selection.selected);
    });

    this.route.queryParams.pipe(
      first()
    ).subscribe((params) => {
      this.pageIndex = params.page ? params.page - 1 : this.pageIndex;
      this.pageSize = params.page_size || this.pageSize;
      this.sort = {
        active: params.sort || this.sort.active,
        direction: params.sort_dir || this.sort.direction,
      };
      this.searchQuery = params.search || this.searchQuery;
    });

    this.searchFailed.subscribe((error) => this.handleSearchFailure(error));
  }

  ngAfterViewInit() {
    this.fetchData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  /**
   * Force the table refresh
   */
  public refresh() {
    this.fetchData();
    this.selection.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterQuery) {
      this.updateSearchQuery();
    }
  }

  /**
   * Toggle current filter opened status
   */
  public toggleFilter() {
    this.filterOpened = !this.filterOpened;
  }

  searchChanged() {
    this.updateSearchQuery();
  }

  updateSearchQuery() {
    let fullQuery = '';

    if (this.searchQuery) {
      fullQuery = ApiProvider.escapeSearchQuery(this.searchQuery);
    }

    if (this.filterQuery) {
      if (fullQuery) {
        fullQuery += ' AND ';
      }

      fullQuery += `(${this.filterQuery})`;
    }

    this.fullQuery = fullQuery;
    this.fetchData();
  }

  deleteSelection() {
    this.dialogService.confirm({
      title: 'Are you sure?',
      message: `You are about to delete ${this.selection.selected.length} items, and this action is irreversible!`,
      cancelButton: 'No!',
      acceptButton: 'Yes, i\'m sure!'
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loadingService.activate('panel-content');
        this.modelProvider.destroy(this.selection.selected).subscribe((destroyed) => {
          let successCount = 0;
          let failedCount = 0;
          let forbiddenCount = 0;

          destroyed.forEach((response) => {
            if (response.destroyed) {
              successCount++;
            } else {
              failedCount++;

              if (response.error === ApiProvider.FORBIDDEN_ERROR) {
                forbiddenCount++;
              }
            }
          });

          if (successCount) {
            this.refresh();
          }

          if (failedCount) {
            const messages = [];

            if (forbiddenCount) {
              messages.push(`You are forbidden to delete ${forbiddenCount} items.`);
              failedCount -= forbiddenCount;
            }

            if (failedCount) {
              messages.push(`Failed to delete ${failedCount} items.`);
            }

            this.dialogService.alert({
              title: 'Error',
              message: messages.join('<br>'),
              closeButton: 'OK'
            });
          }

          this.loadingService.deactivate('panel-content');
        });
      }
    });
  }

  /**
   * Handles the page change event
   *
   * @param $event
   */
  pageChanged($event: PageEvent) {
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex;

    this.fetchData();
  }

  /**
   * Handles the page sort event
   *
   * @param $event
   */
  sortChanged($event: Sort) {
    this.sort = $event;
    this.pageIndex = 0;

    this.fetchData();
  }

  fetchData() {
    if (this.fetchDataSubscription) {
      this.fetchDataSubscription.unsubscribe();
    }
    this.isLoadingResults = true;

    let sortBy = null;
    if (this.sort.active) {
      sortBy = {
        direction: this.sort.direction,
        column: this.sort.active,
      };
    }

    const page = {page: this.pageIndex + 1, size: this.pageSize};
    const columns = this.apiSettings ? this.apiSettings.columns || null : null;
    const include = this.apiSettings ? this.apiSettings.include || null : null;

    this.replaceUrl();

    let observable;

    if (this.fullQuery) {
      observable = this.modelProvider.search(
        this.fullQuery,
        page,
        columns,
        include,
        sortBy
      ).pipe(
        catchError((error: HttpErrorResponse) => {
          this.searchFailed.emit(error);

          return observableOf(
            {data: [], meta: {}}
          );
        })
      );
    } else {
      observable = this.modelProvider.list(
        page,
        columns,
        include,
        sortBy
      );
    }

    this.fetchDataSubscription = observable.pipe(
      map((collection: Collection<any>) => {
        if (collection.meta.last_page < collection.meta.current_page) {
          this.pageIndex = collection.meta.last_page - 1;
          this.fetchData();

          return [];
        }

        this.isLoadingResults = false;
        this.resultsLength = collection.meta.total;

        return collection.data;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => {
      this.dataSource.data = data;
    });
  }

  replaceUrl() {
    const queryParams: any = {};

    const url = this.router.parseUrl(this.router.url);

    if (this.pageIndex > 0) {
      queryParams.page = this.pageIndex + 1;
    }

    if (this.pageSize !== 25) {
      queryParams.page_size = this.pageSize;
    }

    if (this.sort.active !== 'id') {
      queryParams.sort = this.sort.active;
    }

    if (this.sort.direction !== 'desc') {
      queryParams.sort_dir = this.sort.direction;
    }

    if (this.searchQuery) {
      queryParams.search = this.searchQuery;
    }

    if (this.filterJson) {
      queryParams.filter = this.filterJson;
    }

    url.queryParams = queryParams;

    this.router.navigateByUrl(url, {
      replaceUrl: true,
    });
  }

  handleSearchFailure(response: HttpErrorResponse) {
    let message;
    if (response.error.error === 'search_unavailable') {
      message = 'The search is temporarily unavailable. Please try again later.';
    } else {
      message = `The search failed. Please contact support and this error code: "${response.error}"`;
    }

    this.dialogService.alert({
      title: 'Error',
      message,
      closeButton: 'OK'
    });
  }
}
