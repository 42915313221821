import {Model} from '../../abstract/model';
import {SettingValueResponse} from './setting-value.response';

export class SettingValue extends Model {
  value: any;

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: SettingValueResponse): SettingValue {
    return new SettingValue(response);
  }
}
