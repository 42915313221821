import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {AclRoleGroup} from './acl-role-group';
import {AclRoleGroupResponse} from './acl-role-group.response';

@Injectable()
export class AclRoleGroupService extends ApiProvider<AclRoleGroup, AclRoleGroupResponse> {
  protected resourceName = 'acl/role_group';

  collectionFromResponse(response: CollectionResponse<AclRoleGroupResponse>): Array<AclRoleGroup> {
    return AclRoleGroup.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<AclRoleGroupResponse>): AclRoleGroup {
    return AclRoleGroup.createFromResponse(response.data);
  }
}
