import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Client} from './client';

@Injectable()
export class ClientService extends ApiProvider<Client, ClientResponse> {
  protected resourceName = 'client';

  collectionFromResponse(response: CollectionResponse<ClientResponse>): Array<Client> {
    return Client.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<ClientResponse>): Client {
    return Client.createFromResponse(response.data);
  }
}
