
import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {TravelResourceType} from './travel-resource-type';
import {TravelResourceTypeResponse} from './travel-resource-type-response';

/*
  Generated class for the TravelResourceTypeProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TravelResourceTypeService extends ApiProvider<TravelResourceType, TravelResourceTypeResponse> {
  protected resourceName = 'travel_resource_type';

  createFromResponse(response: SingleResponse<TravelResourceTypeResponse>): TravelResourceType {
    return TravelResourceType.createFromResponse(response.data);
  }

  collectionFromResponse(response: CollectionResponse<TravelResourceTypeResponse>): Array<TravelResourceType> {
    return TravelResourceType.collectionFromResponse(response.data);
  }
}
