import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DialogComponent} from './dialog/dialog.component';
import {AlertOptions} from './alert-options';
import {ConfirmOptions} from './confirm-options';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  static TYPE_ALERT = 'alert';
  static TYPE_CONFIRM = 'confirm';

  constructor(
    private dialog: MatDialog,
  ) {}

  alert(options: AlertOptions): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: {
        type: DialogService.TYPE_ALERT,
        options
      },
      disableClose: options.disableClose,
      width: options.width,
      minWidth: options.width ?? '30%'
    });
  }

  confirm(options: ConfirmOptions): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: {
        type: DialogService.TYPE_CONFIRM,
        options
      },
      disableClose: options.disableClose,
      width: options.width,
      minWidth: options.width ?? '30%'
    });
  }
}
