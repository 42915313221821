import { Injectable } from '@angular/core';
import { ApiProvider } from '../api';
import { LocationCountry } from './location-country';

@Injectable()
export class LocationCountryService extends ApiProvider<LocationCountry, LocationCountryResponse> {
  protected resourceName = 'location_country';

  collectionFromResponse(response: CollectionResponse<LocationCountryResponse>): Array<LocationCountry> {
    return LocationCountry.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<LocationCountryResponse>): LocationCountry {
    return LocationCountry.createFromResponse(response.data);
  }
}
