import {Model} from '../../abstract/model';

export class Quotation extends Model {
  id: number;
  start_date: Date;
  end_date: Date;
  url: string;

  /**
   *
   * @returns
   * @param response
   */
  public static createFromResponse(response: any): any {
    const model = new this(response);

    model.start_date = new Date(response.start_date);
    model.end_date = new Date(response.end_date);

    return model;
  }
}
