import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Tag} from './tag';

@Injectable()
export class TagService extends ApiProvider<Tag, TagResponse> {
  protected resourceName = 'tag';

  collectionFromResponse(response: CollectionResponse<TagResponse>): Array<Tag> {
    return Tag.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<TagResponse>): Tag {
    return Tag.createFromResponse(response.data);
  }
}
