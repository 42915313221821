
import { Injectable } from '@angular/core';

import {ApiProvider} from '../api/api';
import {CityDetail} from './city-detail';
import {CityDetailResponse} from './city-detail-response';

/*
  Generated class for the UserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CityDetailProvider extends ApiProvider<CityDetail, CityDetailResponse> {
  protected resourceName = 'city_detail';

  createFromResponse(response: SingleResponse<CityDetailResponse>): CityDetail {
    return CityDetail.createFromResponse(response.data);
  }

  collectionFromResponse(response: CollectionResponse<CityDetailResponse>): Array<CityDetail> {
    return CityDetail.collectionFromResponse(response.data);
  }
}
