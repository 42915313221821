import {Component, HostListener, OnInit} from '@angular/core';
// @ts-ignore
import LogRocket from 'logrocket';
import {environment} from '../environments/environment';
import {DebugService} from './debug/debug.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private debugService: DebugService
  ) {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.altKey && event.shiftKey && event.key.toLowerCase() === 'd') {
      this.debugService.toggle();
    }
  }

  ngOnInit(): void {
    if (environment.production) {
      this.debugService.disable();
    }
  }
}
