import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() message: string;
  @Input() color: string;
  @Input() icon: string;

  @HostBinding('class') get colorClass() {
    return 'color-' + this.color;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
