import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {Entity} from './entity';
import {EntityResponse} from './entity.response';

@Injectable()
export class EntityService extends ApiProvider<Entity, EntityResponse> {
  protected resourceName = 'entity';

  collectionFromResponse(response: CollectionResponse<EntityResponse>): Array<Entity> {
    return Entity.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<EntityResponse>): Entity {
    return Entity.createFromResponse(response.data);
  }
}
