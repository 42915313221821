import {enableProdMode, LOCALE_ID} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
// @ts-ignore
import LogRocket from 'logrocket';

if (environment.production && environment.logrocket) {
  LogRocket.init(environment.logrocket, {
    release: environment.version
  });
}

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localePt, 'pt');

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
  // eslint-disable-next-line no-console
  .catch(err => console.log(err));
