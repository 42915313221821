import { Component, OnInit } from '@angular/core';
import {AuthService} from '../core/auth/auth.service';
import {User} from '../core/user/user';
import { Router } from '@angular/router';
import {LoadingService} from '../ui/loading/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public email: string;
  public password: string;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  /**
   * Check if user is already authenticated, and
   * redirect it to default route if so.
   */
  ngOnInit() {
    this.authService.observeUser().subscribe((user: User) => {
      if (user) {
        this.navigateToDefault();
      }
    });
  }

  /**
   * Navigate to default route
   */
  private navigateToDefault() {
    this.router.navigate(['']);
  }
}
