import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../core/auth/auth.service';
import {User} from '../core/user/user';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  private currentUser: User;
  private roles = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.authService.observeUser().subscribe(user => {
      this.currentUser = user;
      this.updateView();
    });
  }

  @Input()
  set appHasRole(value: string|Array<string>) {
    if (typeof value === 'string') {
      value = [value];
    }

    this.roles = value;
    this.updateView();
  }

  protected checkRoles() {
    let hasAllRoles = true;

    if (this.currentUser) {
      if (this.roles) {
        this.roles.forEach((role) => {
          if (!this.currentUser.hasRole(role)) {
            hasAllRoles = false;
          }
        });
      }
    } else {
      hasAllRoles = false;
    }

    return hasAllRoles;
  }

  private updateView() {
    if (this.checkRoles()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
