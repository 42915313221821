import {Model} from '../../abstract/model';
import {CieloOrder} from '../cielo/cielo-order';
import {SharedPaymentShareResponse} from './shared-payment-share.response';

export class SharedPaymentShare extends Model {
  id: number;
  full_name: string;
  identity: string;
  email: string;
  phone: string;
  value: number;

  created_at: Date;
  updated_at: Date;

  cielo_order?: CieloOrder;

  /**
   * The model type on API
   *
   * @type {string}
   */
  model_type = 'Cdtsys\\GenericCampaign\\Models\\SharedPaymentShare';

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: SharedPaymentShareResponse): SharedPaymentShare {
    const share = new SharedPaymentShare(response);

    share.created_at = new Date(response.created_at);
    share.updated_at = new Date(response.updated_at);

    if (response.cielo_order) {
      share.cielo_order = CieloOrder.createFromResponse(response.cielo_order);
    }

    return share;
  }
}
