import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {TravelResource} from './travel-resource';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {TravelResourceResponse} from './travel-resource.response';

@Injectable()
export class TravelResourceService extends ApiProvider<TravelResource, TravelResourceResponse> {
  protected resourceName = 'travel_resource';

  createFromResponse(response: SingleResponse<TravelResourceResponse>): TravelResource {
    return TravelResource.createFromResponse(response.data);
  }

  collectionFromResponse(response: CollectionResponse<TravelResourceResponse>): Array<TravelResource> {
    return TravelResource.collectionFromResponse(response.data);
  }

  list(
    page?: PaginationParameters,
    columns?: Array<string>,
    include?: Array<string>,
    sortBy?: { direction: string; column: string },
    url?: URL,
    queryParams?: any
  ): Observable<Collection<TravelResource>> {
    if (typeof queryParams === 'object') {
      if (!url) {
        url = new URL(`${environment.api.endpoint}/${this.resourceName}`);
      }
      Object.keys(queryParams).map((key) => {
        url.searchParams.append(key, queryParams[key]);
      });
    }
    return super.list(page, columns, include, sortBy, url);
  }
}
