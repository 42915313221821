import {Model} from '../../abstract/model';
import {LocationCity} from '../location/location-city';
import {TravelResource} from '../travel-resource/travel-resource';
import {TravelResourceGroupResponse} from './travel-resource-group-response';

export class TravelResourceGroup extends Model {
  public id: number;
  public label: string;
  public location_city: LocationCity;
  public relevance: number;
  public travel_resource_count: number;
  public travel_resources: Array<TravelResource>;
  public valid: boolean;
  public created_at: Date;
  public updated_at: Date;
  public localized: {
    [key: string]: {
      name: string;
      country_name: string;
      guide_description: string;
      proposal_description: string;
    };
  };

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: TravelResourceGroupResponse): TravelResourceGroup {
    const travelResourceGroup = new TravelResourceGroup(response);
    travelResourceGroup.travel_resources = travelResourceGroup.travel_resources
      ? TravelResource.collectionFromResponse(travelResourceGroup.travel_resources)
      : [];
    travelResourceGroup.location_city = travelResourceGroup.location_city
      ? LocationCity.createFromResponse(travelResourceGroup.location_city)
      : null;
    travelResourceGroup.created_at = travelResourceGroup.created_at ? new Date(travelResourceGroup.created_at) : null;
    travelResourceGroup.updated_at = travelResourceGroup.updated_at ? new Date(travelResourceGroup.updated_at) : null;
    return travelResourceGroup;
  }

  toString() {
    return this.label;
  }
}
