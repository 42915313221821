import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {ClientRequestTimelineItem} from './client-request-timeline-item';
import {ClientRequestTimelineItemResponse} from './client-request-timeline-item.response';

@Injectable()
export class ClientRequestTimelineItemService extends ApiProvider<ClientRequestTimelineItem, ClientRequestTimelineItemResponse> {
  protected resourceName = 'client_request/timeline_item';

  collectionFromResponse(response: CollectionResponse<ClientRequestTimelineItemResponse>): Array<ClientRequestTimelineItem> {
    return ClientRequestTimelineItem.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<ClientRequestTimelineItemResponse>): ClientRequestTimelineItem {
    return ClientRequestTimelineItem.createFromResponse(response.data);
  }
}
