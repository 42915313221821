<mat-card>
  <mat-card-title>
    Politica de privacidade
  </mat-card-title>
  <mat-card-content>
    <h3>Informações coletadas</h3>
    <p>O uso deste aplicativo coleta algumas informações necessárias para seu funcionamento:</p>
    <h4>1. Integração com autenticação do Google</h4>
    <p>Este aplicativo depende da autenticação do Google para funcionamento. Esta autentição compatilha
      com o aplicativo seu endereço de e-mail e todas as informações publicamente visíveis de sua conta.</p>
  </mat-card-content>
</mat-card>
