import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {SharedPaymentShare} from './shared-payment-share';
import {SharedPaymentShareResponse} from './shared-payment-share.response';

@Injectable()
export class SharedPaymentShareService extends ApiProvider<SharedPaymentShare, SharedPaymentShareResponse> {
  protected resourceName = 'shared_payment_share';

  collectionFromResponse(response: CollectionResponse<SharedPaymentShareResponse>): Array<SharedPaymentShare> {
    return SharedPaymentShare.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<SharedPaymentShareResponse>): SharedPaymentShare {
    return SharedPaymentShare.createFromResponse(response.data);
  }
}
