import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {TravelConsultant} from './travel-consultant';
import {TravelConsultantResponse} from './travel-consultant.response';
import {Observable, of} from 'rxjs';
import {TravelConsultantSettings} from './travel-consultant-settings';

@Injectable()
export class TravelConsultantService extends ApiProvider<TravelConsultant, TravelConsultantResponse> {
  protected resourceName = 'travel_consultant';

  collectionFromResponse(response: CollectionResponse<TravelConsultantResponse>): Array<TravelConsultant> {
    return TravelConsultant.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<TravelConsultantResponse>): TravelConsultant {
    return TravelConsultant.createFromResponse(response.data);
  }

  /**
   * @todo
   */
  loadSettings(): Observable<TravelConsultantSettings> {
    return of({
      distribution_mode: 'queue'
    });
  }

  /**
   * @todo
   * @param settings
   */
  saveSettings(settings: TravelConsultantSettings): Observable<TravelConsultantSettings> {
    return of(settings);
  }
}
