import {Model} from '../../abstract/model';
import {TravelResourceType} from '../travel-resource-type/travel-resource-type';
import {LocationCity} from '../location/location-city';
import {File} from '../file/file';
import {Tag} from '../tag/tag';
import {TravelResourceResponse} from './travel-resource.response';
import {Contact} from '../contact/contact.model';

export class TravelResource extends Model {
  id: number;
  name: string;
  status: string;
  internal_description: string;
  operating_schedule: any; // @todo: To be defined, irrelevant now
  luxury_classification: number;
  rating: number;
  rated_at: Date;
  available_from: Date;
  available_until: Date;
  location_street: string;
  location_number: string;
  location_zip: string;
  location_locality: string;
  location_latitude: number;
  location_longitude: number;
  location_gps_name: string;
  location_everywhere: boolean;
  contact_primary: string;
  contact_alternative: string;
  contact_email: string;
  website: string;
  near_notification_options: any; // @todo: To be defined, irrelevant now
  itinerary: any; // @todo: To be defined, irrelevant now
  itinerary_best_time: any; // @todo: To be defined, irrelevant now
  itinerary_duration: any; // @todo: To be defined, irrelevant now

  travel_resource_type: TravelResourceType;
  location_city: LocationCity;
  travel_guide_city: LocationCity;
  parking_resource: TravelResource;

  files: Array<File>;
  images: Array<File>;
  tags: Array<Tag>;
  contacts: Array<Contact>;
  localized: any;

  created_at: Date;
  updated_at: Date;

  get image() {
    return this.images[0];
  }

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: TravelResourceResponse): TravelResource {
    const model = new TravelResource(response);

    model.rated_at = response.rated_at ? new Date(response.rated_at) : null;
    model.available_from = response.available_from ? new Date(response.available_from) : null;
    model.available_until = response.available_until ? new Date(response.available_until) : null;
    model.travel_resource_type = TravelResourceType.createFromResponse(response.travel_resource_type);
    model.location_city = response.location_city ? LocationCity.createFromResponse(response.location_city) : null;
    model.travel_guide_city = response.travel_guide_city ? LocationCity.createFromResponse(response.travel_guide_city) : null;
    model.files = response.files ? File.collectionFromResponse(response.files) : null;
    model.images = response.images ? File.collectionFromResponse(response.images) : null;
    model.tags = response.tags ? Tag.collectionFromResponse(response.tags) : null;
    model.parking_resource = response.parking_resource ? TravelResource.createFromResponse(response.parking_resource) : null;
    model.contacts = response.contacts ? Contact.collectionFromResponse(response.contacts) : null;

    model.created_at = new Date(response.created_at);
    model.updated_at = response.updated_at ? new Date(response.updated_at) : new Date(response.created_at);

    if (response.operating_schedule === null) {
      model.operating_schedule = [];
    }

    return model;
  }

  toString() {
    return `${this.name}`;
  }

  get address() {
    const components = [];

    if (this.location_street) {
      components.push(
        `${this.location_street}` + (this.location_number ? `, ${this.location_number}` : '')
      );
    }

    components.push(this.location_locality);
    components.push(this.location_city ? this.location_city.toString() : null);
    components.push(this.location_zip);

    return components.filter((component) => !!component).join(' - ');
  }
}
