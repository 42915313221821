<app-panel [panelTitle]="panelTitle">
  <mat-toolbar *ngIf="!selection.selected.length">
    <app-fill>
      <mat-form-field floatLabel="never">
        <button mat-icon-button matPrefix>
          <mat-icon >search</mat-icon>
        </button>
        <input matInput [(ngModel)]="searchQuery" (input)="searchChanged()" placeholder="Search here...">
        <button mat-icon-button (click)="searchQuery = ''; searchChanged()" *ngIf="searchQuery" matSuffix>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>
    </app-fill>
    <ng-content select=".toolbar-buttons"></ng-content>
    <button *ngIf="enableFilters"
            mat-icon-button
            (click)="toggleFilter()" title="Filters">
      <mat-icon [matBadge]="filterCount"
                [matBadgeHidden]="!filterCount"
                matBadgeColor="accent"
                matBadgePosition="above after">
        filter_list
      </mat-icon>
    </button>
  </mat-toolbar>
  <mat-toolbar *ngIf="selection.selected.length">
    <span>{{ selection.selected.length }} rows selected</span>
    <app-fill></app-fill>
    <button mat-icon-button (click)="deleteSelection()" title="Delete" *appHasRole="deleteRole">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav-container class="panel-content">
    <mat-sidenav #filter mode="side" [opened]="filterOpened" position="end">
      <ng-content select=".filter-content"></ng-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="content-overlay" *ngIf="isLoadingResults">
        <mat-spinner color="accent"></mat-spinner>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource"
                   matSort
                   (matSortChange)="sortChanged($event)"
                   [matSortActive]="sort?.active"
                   [matSortDirection]="sort?.direction"
                   [matSortDisableClear]="true">
          <ng-container matColumnDef="select" *ngIf="selectable">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" (click)="selection.toggle(row)">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" [ngClass]="{'shrink': column.shrink}">{{ column.label }}</th>

            <td mat-cell
                *matCellDef="let model"
                [ngClass]="{'shrink': column.shrink, 'highlight': hightlight ? highlight(model) : false}"
                (click)="rowClick.emit(model)">
              <span *ngIf="column.resolver && !column.cast">{{ column.resolver(model) }}</span>
              <span *ngIf="column.resolver && column.cast === 'icons'">
                <mat-icon *ngFor="let icon of column.resolver(model)" [title]="icon.title" [style.color]="icon.color || 'inherit'">{{ icon.icon }}</mat-icon>
              </span>
              <span *ngIf="!column.resolver">
                <span *ngIf="!column.cast">{{ model[column.name] }}</span>
                <span *ngIf="column.cast === 'date'">{{ model[column.name] | date }}</span>
                <span *ngIf="column.cast === 'shortdate'">{{ model[column.name] | date: 'shortDate' }}</span>
                <span *ngIf="column.cast === 'datetime'">{{ model[column.name] | date: 'short' }}</span>
                <span *ngIf="column.cast === 'timeago'">{{ model[column.name] | timeAgo }}</span>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns;"></tr>
        </table>
      </div>
      <mat-paginator #paginator
                     [length]="resultsLength"
                     (page)="pageChanged($event)"
                     [pageIndex]="pageIndex"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[25,50,100]"
                     *ngIf="paginate">
      </mat-paginator>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="fab-buttons">
    <ng-content select="button[mat-fab]"></ng-content>
  </div>
</app-panel>


