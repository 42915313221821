import {Model} from '../../abstract/model';
import {ContactResponse} from './contact.response';

export class Contact extends Model {
  id: number;
  type: string;
  name: string;
  contact: string;
  observation: string;

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: ContactResponse): Contact {
    return new Contact(response);
  }
}
