import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {GenericCampaign} from './generic-campaign';
import {GenericCampaignResponse} from './generic-campaign.response';

@Injectable()
export class GenericCampaignService extends ApiProvider<GenericCampaign, GenericCampaignResponse> {
  protected resourceName = 'generic_campaign';

  collectionFromResponse(response: CollectionResponse<GenericCampaignResponse>): Array<GenericCampaign> {
    return GenericCampaign.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<GenericCampaignResponse>): GenericCampaign {
    return GenericCampaign.createFromResponse(response.data);
  }
}
