import {FormGroup} from '@angular/forms';

export class PasswordValidator {
  static MatchPassword(AC: FormGroup) {
    const password = AC.get('password').value;
    const confirmPassword = AC.get('passwordConfirmation').value;

    if (!password || !password.length) {
      return null;
    }

    if (password !== confirmPassword) {
      AC.get('passwordConfirmation').setErrors( {MatchPassword: true} );
    } else {
      return null;
    }
  }
}
