import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PasswordValidator} from '../../core/validators/password';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {User} from '../../core/user/user';
import {UserProvider} from '../../core/user/user.provider';
import {LoadingService} from '../loading/loading.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private userProvider: UserProvider,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {

  }

  ngOnInit(): void {
    this.form = this.buildForm();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.loadingService.activate('password');
    this.userProvider.update(this.user.id, {password: this.form.value.password}).subscribe(() => {
      this.loadingService.deactivate('password');
      this.snackBar.open('You password was updated!', 'OK', {duration: 3000});
      this.dialogRef.close();
    });
  }

  /**
   * Build Form
   *
   * @returns
   */
  private buildForm() {
    const formConfig = {
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirmation: [''],
    };

    return this.formBuilder.group(formConfig, {
      validator: PasswordValidator.MatchPassword
    });
  }
}
