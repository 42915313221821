import { Injectable } from '@angular/core';
import {ApiProvider} from '../api';
import {AclTemplate} from './acl-template';
import {AclTemplateResponse} from './acl-template.response';

@Injectable()
export class AclTemplateService extends ApiProvider<AclTemplate, AclTemplateResponse> {
  protected resourceName = 'acl/template';

  collectionFromResponse(response: CollectionResponse<AclTemplateResponse>): Array<AclTemplate> {
    return AclTemplate.collectionFromResponse(response.data);
  }

  createFromResponse(response: SingleResponse<AclTemplateResponse>): AclTemplate {
    return AclTemplate.createFromResponse(response.data);
  }
}
