import {Model} from '../../abstract/model';
import {SettingResponse} from './setting.response';

export class Setting extends Model {
  code: string;
  group_code: string;
  options: {
    label: string;
    description: string;
    nullable: string;
    scope: 'global'|'user';
    type: 'enum';
    default: any|null;
    options?: {[key: string]: string};
  };

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: SettingResponse): Setting {
    return new Setting(response);
  }
}
