import {Model} from '../../abstract/model';

export class ClientRequestStatus extends Model {

  static STATUSES_LABELS = [
    'Unattended',
    'Waiting Consultant Reply',
    'Waiting Client Reply',
    'On hold',
    'Waiting Fee Payment',
    'Proposal rejected',
    'Closed by Consultant',
    'Travel Cancelled',
  ];
  id: number;
  status: number;
  start_date: Date;
  end_date: Date;


  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: ClientRequestStatusResponse): ClientRequestStatus {
    const clientRequestStatus = new ClientRequestStatus(response);

    clientRequestStatus.start_date = new Date(response.start_date);
    clientRequestStatus.end_date = new Date(response.end_date);

    return clientRequestStatus;
  }

  /**
   * @returns
   */
  public toString(): string {
    return ClientRequestStatus.STATUSES_LABELS[this.status];
  }
}
