import {Model} from '../abstract/model';
import {AclRole} from '../api/acl/acl-role';
import {UserResponse} from './user-response';
import {PermissionGroup} from '../api/permission-group/permission-group';

export class User extends Model {
  id: number;
  name: string;
  surname: string;
  email: string;
  permission_group: PermissionGroup;
  email_signature: string;
  created_at: Date;
  updated_at: Date;
  acl_roles: Array<AclRole>;

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: UserResponse): User {
    const user = new User(response);

    user.acl_roles = user.acl_roles ? AclRole.collectionFromResponse(response.acl_roles) : [];
    user.permission_group = user.permission_group ? PermissionGroup.createFromResponse(response.permission_group) : null;

    return user;
  }

  get full_name() {
    return `${this.name} ${this.surname}`;
  }

  /**
   * Check if user has given role
   *
   * @param role
   */
  public hasRole(role: string|AclRole): boolean {
    if (role instanceof AclRole) {
      role = role.code;
    }

    return !!this.acl_roles.find((userAclRole: AclRole) => userAclRole.code === role);
  }

  toString() {
    return this.full_name;
  }
}
