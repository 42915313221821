import {Component, OnInit} from '@angular/core';
import {AuthService} from '../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {catchError, switchMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {LoadingService} from '../ui/loading/loading.service';
import {DialogService} from '../ui/dialog/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private legacy = false;
  private intendedUrl = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
  ) {
  }

  /**
   * Check if user is already authenticated, and
   * redirect it to default route if so.
   */
  ngOnInit() {
    this.loadingService.activate('login');
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.legacy === 'true') {
        this.legacy = true;
      }

      this.intendedUrl = queryParams.intendedUrl || '';
    });

    this.setupGapi();
  }

  async setupGapi() {
    await this.authService.renderSignIn2('g-signin', (r) => this.googleSignInSuccessHandler(r));
    this.loadingService.deactivate('login');
  }

  googleSignInSuccessHandler(response) {
    const id_token = response.getAuthResponse().id_token;

    this.authService.loginWithGoogleUserIdToken(id_token)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400 && err.error.error === 'invalid_grant') {
            return this.authService.googleLogout().pipe(
              switchMap(() => this.showInvalidGrantError()),
              map(() => false)
            );
          }
          return of(false);
        })
      )
      .subscribe((success: boolean) => {
        if (success) {
          if (this.legacy) {
            this.authService.legacyLoginWithGoogleUserIdToken(id_token, this.intendedUrl);
          } else {
            this.navigateBack();
          }
        }
      });

  }

  /**
   * Navigate to back to intended url
   */
  private navigateBack(): void {
    this.router.navigate([this.intendedUrl]);
  }

  /**
   * @private
   */
  private showInvalidGrantError() {
    return this.dialogService.alert({
      title: 'Invalid Google Account',
      message: 'Check if you are signing in with correct email.',
      closeButton: 'OK'
    }).afterOpened();
  }
}
