import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PanelComponent} from './panel/panel.component';
import {ChangePasswordDialogComponent} from './panel/change-password-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ModelListComponent } from './model-list/model-list.component';
import {FillComponent} from './panel/fill.component';
import {HasRoleDirective} from './has-role.directive';
import {HasNotRoleDirective} from './has-not-role.directive';
import { LoadingComponent } from './loading/loading.component';
import {AppLoadingDirective} from './loading/loading.directive';
import {LoadingService} from './loading/loading.service';
import { DialogComponent } from './dialog/dialog/dialog.component';
import { AlertComponent } from './alert/alert.component';
import {PipesModule} from '../pipes/pipes.module';
import {DebugModule} from '../debug/debug.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,

    /**
     * Angular Material modules (see https://material.angular.io)
     */
    MatBadgeModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PipesModule,
    DebugModule,
  ],
  declarations: [
    PanelComponent,
    FillComponent,
    ModelListComponent,
    ChangePasswordDialogComponent,
    HasRoleDirective,
    HasNotRoleDirective,
    AppLoadingDirective,
    LoadingComponent,
    DialogComponent,
    AlertComponent
  ],
  exports: [
    PanelComponent,
    FillComponent,
    ModelListComponent,
    HasRoleDirective,
    HasNotRoleDirective,
    AppLoadingDirective,
    LoadingComponent,
    AlertComponent
  ],
  providers: [
    LoadingService,
  ]
})
export class UiModule { }
