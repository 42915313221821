import {Model} from '../../abstract/model';
import {File} from '../file/file';
import {EmailMessageAttachmentResponse} from './email-message-attachment.response';

export class EmailMessageAttachment extends Model {
  id: number;
  content_id?: string;
  original_filename: string;
  file: File;

  /**
   * @returns
   * @param response
   */
  public static createFromResponse(response: EmailMessageAttachmentResponse): EmailMessageAttachment {
    const model = new this(response);

    model.file = File.createFromResponse(response.file);

    return model;
  }
}
