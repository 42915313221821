import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './auth-guard.service';
import {GuestGuard} from './guest-guard.service';
import {LoginComponent} from '../login/login.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {PrivacyPolicyComponent} from '../privacy-policy/privacy-policy.component';
import {LogoutComponent} from '../logout/logout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('../user/user.module').then(m => m.UserModule)
  },
  {
    path: 'city_detail',
    loadChildren: () => import('../city-detail/city-detail.module').then(m => m.CityDetailModule)
  },
  {
    path: 'travel_resource',
    loadChildren: () => import('../travel-resource/travel-resource.module').then(m => m.TravelResourceModule)
  },
  {
    path: 'travel_resource_group',
    loadChildren: () => import('../travel-resource-group/travel-resource-group.module').then(m => m.TravelResourceGroupModule)
  },
  {
    path: 'client_request',
    loadChildren: () => import('../client-request/client-request.module').then(m => m.ClientRequestModule)
  },
  {
    path: 'person',
    loadChildren: () => import('../person/person.module').then(m => m.PersonModule)
  },
  {
    path: 'shared_payment',
    loadChildren: () => import('../shared-payment/shared-payment.module').then(m => m.SharedPaymentModule)
  },
  {
    path: 'generic_campaign',
    loadChildren: () => import('../generic-campaign/generic-campaign.module').then(m => m.GenericCampaignModule)
  },
  {
    path: 'kibana_report',
    loadChildren: () => import('../kibana/kibana.module').then(m => m.KibanaModule)
  },
  {
    path: 'acl',
    loadChildren: () => import('../acl/acl.module').then(m => m.AclModule)
  },
  {
    path: 'rd',
    loadChildren: () => import('../rd/rd.module').then(m => m.RdModule)
  },
  {
    path: 'travel_consultant',
    loadChildren: () => import('../travel-consultant/travel-consultant.module').then(m => m.TravelConsultantModule)
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ],
  providers: [
    AuthGuard,
    GuestGuard
  ]
})
export class RoutingModule { }
