import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

interface TagState {
  tag: string;
  active: boolean;
}

@Injectable()
export class LoadingService {
  private activeTags = [];
  private tagsStateSubject: Subject<TagState> = new Subject<{tag: string; active: boolean}>();

  /**
   * Activate given loading tag and notify observers
   *
   * @param tag
   */
  public activate(tag: string) {
    if (!this.isActivated(tag)) {
      this.activeTags.push(tag);
      this.tagsStateSubject.next({tag, active: true});
    }
  }

  /**
   * Deactivate given loading tag and notify observers
   *
   * @param tag
   */
  public deactivate(tag: string) {
    if (this.isActivated(tag)) {
      this.activeTags = this.activeTags.filter((activeTag: string) => activeTag !== tag);
      this.tagsStateSubject.next({tag, active: false});
    }
  }

  /**
   * Observe activation state changes for given loading tag
   *
   * @param tag
   */
  public observe(tag: string) {
    return this.tagsStateSubject.asObservable().pipe(
      filter((tagState: TagState) => tagState.tag === tag),
      map((tagState: TagState) => tagState.active)
    );
  }

  /**
   * Check if given loating tag is activated
   *
   * @param tag
   */
  private isActivated(tag: string) {
    return this.activeTags.includes(tag);
  }
}
