import {Model} from '../../abstract/model';
import {User} from '../../user/user';
import {ClientRequestStatus} from './client-request-status';
import {Origin} from '../origin/origin';
import {TravelConsultant} from '../travel-consultant/travel-consultant';
import {Client} from '../client/client';
import {File} from '../file/file';
import {Quotation} from '../quotation/quotation';
import {ClientRequestTimelineItem} from './client-request-timeline-item';
import {Conversion} from '../conversion/conversion';
import {Fileable} from '../../abstract/fileable';
import {ClientRequestResponse} from './client-request.response';
import {EmailMessageEventResponse} from '../email-message/email-message-event.response';

export class ClientRequest extends Model implements Fileable {
  id: number;
  briefing: any = {};
  deal_probability: number;
  email_capture_code: string;
  capture_email_address: string;
  travel_design_fee_receipt_id: number;

  creator_user: User;
  updater_user: User;
  client_type: string;
  client: Client;
  origin: Origin;
  travel_consultant: TravelConsultant;
  status: ClientRequestStatus;
  files: Array<File> = [];
  statuses: Array<ClientRequestStatus> = [];
  quotations: Array<Quotation> = [];
  timeline_items: Array<ClientRequestTimelineItem> = [];
  watchers: Array<User> = [];
  conversions: Array<Conversion> = [];
  watched: boolean;
  fee_paid: boolean;

  created_at: Date;
  updated_at: Date;

  unread_email_count?: number;

  /**
   * The model type on API
   *
   * @type {string}
   */
  model_type = 'App\\Models\\ClientRequest';

  /**
   * @param response
   * @returns
   */
  public static createFromResponse(response: ClientRequestResponse): ClientRequest {
    const clientRequest = new ClientRequest(response);

    clientRequest.briefing = response.briefing;
    clientRequest.created_at = new Date(response.created_at);
    clientRequest.updated_at = new Date(response.updated_at);

    clientRequest.creator_user = response.creator_user ? User.createFromResponse(response.creator_user) : null;
    clientRequest.updater_user = clientRequest.creator_user ? User.createFromResponse(response.updater_user) : null;
    clientRequest.client = response.client ? Client.createFromResponse(response.client) : null;
    clientRequest.origin = response.origin ? Origin.createFromResponse(response.origin) : null;
    clientRequest.travel_consultant = response.travel_consultant ? TravelConsultant.createFromResponse(response.travel_consultant) : null;
    clientRequest.status = response.status ? ClientRequestStatus.createFromResponse(response.status) : null;

    clientRequest.files = response.files ? File.collectionFromResponse(response.files) : null;
    clientRequest.statuses = response.statuses ? ClientRequestStatus.collectionFromResponse(response.statuses) : null;
    clientRequest.quotations = response.quotations ? Quotation.collectionFromResponse(response.quotations) : null;
    clientRequest.timeline_items = response.timeline_items
      ? ClientRequestTimelineItem.collectionFromResponse(response.timeline_items)
      : null;
    clientRequest.watchers = response.watchers ? User.collectionFromResponse(response.watchers) : null;
    clientRequest.conversions = response.conversions ? Conversion.collectionFromResponse(response.conversions) : null;

    return clientRequest;
  }

  /**
   * Check if the client is a draft
   *
   * @returns
   */
  public isDraftClient() {
    return this.client_type !== 'App\\Models\\Client';
  }

  /**
   * @param timelineItem
   */
  public updateOrCreateTimelineItem(timelineItem: ClientRequestTimelineItem) {
    let updated = false;

    this.timeline_items = this.timeline_items.map((item) => {
      if (item.id === timelineItem.id) {
        updated = true;
        return timelineItem;
      } else {
        return item;
      }
    }).slice();

    if (!updated) {
      this.timeline_items.push(timelineItem);
      this.timeline_items = this.timeline_items.slice();
    }

    return Object.assign(new ClientRequest(), this);
  }

  public updateEmailMessageSentAt(emailMessageId: number, sent_at: Date) {
    let updated = false;

    this.timeline_items.forEach((item) => {
      if (item.email_message && item.email_message.id === emailMessageId) {
        item.email_message.sent_at = sent_at;
        updated = true;
      }
    });

    if (updated) {
      this.timeline_items = this.timeline_items.slice();
    }

    return Object.assign(new ClientRequest(), this);
  }

  appendEmailMessageEvent(emailMessageId: number, event: EmailMessageEventResponse) {
    let updated = false;

    this.timeline_items.forEach((item) => {
      if (item.email_message && item.email_message.id === emailMessageId) {
        if (item.email_message.occurred_events.indexOf(event.event_type) === -1) {
          item.email_message.occurred_events.push(event.event_type);
          updated = true;
        }
      }
    });

    if (updated) {
      this.timeline_items = this.timeline_items.slice();
    }

    return Object.assign(new ClientRequest(), this);
  }
}
