import {Model} from '../../abstract/model';
import {EmailMessageAttachment} from './email-message-attachment';
import {EmailMessageResponse} from './email-message.response';

export class EmailMessage extends Model {
  static TYPE_INBOUND = 0;
  static TYPE_OUTBOUND = 1;

  id: number;
  type: number;
  to: Array<AddressPart>;
  from: Array<AddressPart>;
  cc: Array<AddressPart>;
  subject: string;
  body: string;
  sent_at: Date;
  delivered_at: Date;
  read_at: Date;
  attachments: Array<EmailMessageAttachment> = [];
  in_reply_to_id: number;
  occurred_events: Array<string>;

  public static replaceCidByUrl(emailMessage: EmailMessage, body: string = null, updateOriginal: boolean = false) {
    body = body ? body : emailMessage.body;
    emailMessage.attachments.forEach((attachment) => {
      body = body.replace(`src="cid:${attachment.content_id}"`, `src="${attachment.file.download_link}"`);
    });

    if (updateOriginal) {
      emailMessage.body = body;
    }

    return body;
  }

  public static replaceUrlByCid(emailMessage: EmailMessage, body: string = null, updateOriginal: boolean = false) {
    body = body ? body : emailMessage.body;

    emailMessage.attachments.forEach((attachment) => {
      body = body.replace(`src="${attachment.file.download_link}"`, `src="cid:${attachment.content_id}"`);
    });

    if (updateOriginal) {
      emailMessage.body = body;
    }

    return body;
  }

  get sending(): boolean {
    return !this.sent_at;
  }

  get delivered(): boolean {
    return this.occurred_events.indexOf('delivered') !== -1;
  }

  get rejected(): boolean {
    return this.occurred_events.indexOf('rejected') !== -1;
  }

  get failed(): boolean {
    return this.occurred_events.indexOf('failed') !== -1;
  }

  get temporary_failed(): boolean {
    return this.occurred_events.indexOf('temporary_failed') !== -1;
  }

  get complained(): boolean {
    return this.occurred_events.indexOf('complained') !== -1;
  }

  get opened(): boolean {
    return this.occurred_events.indexOf('opened') !== -1;
  }

  /**
   * @returns
   * @param response
   */
  public static createFromResponse(response: EmailMessageResponse): EmailMessage {
    const emailMessage = new this(response);

    emailMessage.sent_at = emailMessage.sent_at ? new Date(response.sent_at) : null;
    emailMessage.delivered_at = emailMessage.delivered_at ? new Date(response.delivered_at) : null;
    emailMessage.read_at = emailMessage.read_at ? new Date(response.read_at) : null;

    if (response.attachments) {
      response.attachments.forEach((attachment) => {
        emailMessage.attachments.push(EmailMessageAttachment.createFromResponse(attachment));
      });
    }

    // Replace inline cid by the actual url
    EmailMessage.replaceCidByUrl(emailMessage, null, true);

    return emailMessage;
  }

  /**
   * @returns
   */
  public isInbound() {
    return this.type === EmailMessage.TYPE_INBOUND;
  }

  /**
   * @returns
   */
  public isOutbound() {
    return this.type === EmailMessage.TYPE_OUTBOUND;
  }
}
