<h1 mat-dialog-title>{{ data.options.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.options.message }}</p>
</div>
<div mat-dialog-actions *ngIf="data.type === 'alert'">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{data.options.closeButton}}</button>
</div>
<div mat-dialog-actions *ngIf="data.type === 'confirm'" align="end">
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{data.options.cancelButton}}</button>
  <button *ngIf="data.options.acceptButton"
          mat-button
          [mat-dialog-close]="true"
          [color]="data.options.acceptButtonColor || 'accent'">
    {{ data.options.acceptButton }}
  </button>
  <button *ngFor="let button of data.options.acceptButtons"
          mat-button
          [mat-dialog-close]="button.return"
          [color]="button.color || 'accent'">
    {{ button.text }}
  </button>
</div>
