import {AuthTokenProvider} from './auth-token-provider';
import {first, mergeMap} from 'rxjs/operators';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authTokenProvider;

  constructor(
    private injector: Injector
  ) {
    this.authTokenProvider = this.injector.get(AuthTokenProvider);
  }

  /**
   * Intercept requests adding the AuthToken if available
   *
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authTokenProvider.observeToken().pipe(
      first(),
      mergeMap((token: AuthToken) => {
        if (token) {
          if (!request.headers.has('DisableAuthInterceptor')) {
            request = request.clone({setHeaders: {
              authorization: `${token.token_type} ${token.access_token}`
            }});
          }
        }

        return next.handle(request);
      })
    );
  }
}
